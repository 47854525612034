import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Spinner from "react-bootstrap/Spinner";


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { EffectCoverflow } from "swiper";
// SwiperCore.use([Navigation, Pagination]);

function Slider() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    fetch("https://ithub.thekashth.com/api/work").then((result) => {
      result.json().then((resp) => {
        setData(resp.data);
        setLoader(false);
      });
    });
  }, []);

  return (
    <>
      <div id="slider" className="slider-section">
        <div className="col-md-12">
          <div className=" slider-text-h2">
            <h2 className="py-3">Our Work</h2>
          </div>
        </div>

        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          spaceBetween={50}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          navigation
          pagination={true}
          breakpoints={{
            576: {
              // width: 576,
              spaceBetween: 5,
              slidesPerView: 1,
            },
            768: {
              // width: 768,
              slidesPerView: 2,
            },
            1220: {
              // width: 1280,
              slidesPerView: 3,
            },
          }}
          modules={[Navigation, EffectCoverflow, Pagination, Scrollbar, A11y]}
          className="mySwiper"
        >
          {data.map((item) => (
            <SwiperSlide>
             
                <div className="w-80">
                {loader ? (
                <div className="featued-spinner">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                  <div className="slide-card">
                    <div>
                      <img
                        className="imagebx img-fluid"
                        src={"https://ithub.thekashth.com/" + item.image}
                      />
                    </div>
                    <div className="slider-text">
                      <h2>{item.title}</h2>
                      <p>{item.description} </p>
                    </div>
                  </div>
              )}
                </div>
              
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default Slider;
