import React from "react";
import Saly from "../img/Saly-10.png";
import {Link} from "react-router-dom";
function Header() {
  return (
    <>
      <header id="header">
        <div className="container">
          <div className="row">
            <div className="col-md-6 header-text order-2 order-md-1">
              <h1 data-aos="fade-down-right" data-aos-duration="1200">
                Work by Day. Game by <span>Night</span>.
              </h1>
              <p>
              A Software Services Company That Specializes In Providing Custom Software Development, Web Development, Mobile App Development, And Cloud-Based Solutions.              </p>
			  <div className="btn-icon">
                <div className="hire-us hover">
                  <Link to="/Contacts">
                    <button className="header-btn">Hire Us</button>
                  </Link>
                </div>
                <div className="header-icon">
                  <a href="">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                  <a href="">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a href="">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a href="">
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
				</div>
            </div>
            <div className="col-md-6 header-img order-1 order-md-2">
              <img src={Saly} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </header>
      
    </>
  );
}
export default Header;
