import React from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <>
      <footer className="footer pt-5">
        <div className="container-fluid">
          <div className="container">
            <div className="row pb-5">
              <div className="col-md-3 footer-text mb-3">
                <h2>
                  <img 
                      src={require('../img/logo/logo192.png')}
                      width={120} 
                  />
                </h2>
                <p>
                  Transforming businesses through innovative technology solutions.
                </p>
                <div className="footer-icon">
                  <a href="">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                  <a href="">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a href="">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </div>
              </div>

              <div className="col-md-4 footer-text">
                <h2>GET IN TOUCH</h2>
                <p><i className="fa-solid fa-phone"></i>
                  <strong>Phone: </strong><a className="link" href="tel:+918502003023">+91 8502003023</a>
                </p>
                <p>
                  <i className="fa-regular fa-envelope"></i>
                  <strong>Email: </strong>
                  <a className="link" href="mailto:itwhizzpvt@gmail.com" >itwhizzpvt@gmail.com</a>
                </p>
                <p><i className="fa-solid fa-location-dot"></i>
                  <strong>Location: </strong> salawas <br />
                  <span>Jodhpur, Rajasthan</span>
                </p>
              </div>
              <div className="col-md-3 footer-link">
                <h2>QUICK LINKS</h2>
                <p>
                  <Link
                      to={'/'}
                      className="footer_link"
                    >
                      Home
                  </Link>
                  <br />
                  <Link
                    to={'/Contacts'}
                    className="footer_link"
                  >
                    Contact
                  </Link>
                  <br />
                  <Link
                    to={'/about-us'}
                    className="footer_link"
                  >
                    About
                  </Link>
                  <br />
                  <Link
                    to={'blog'}
                    className="footer_link"
                  >
                    Blogs
                  </Link><br/>
                  <a href={'/sitemap.xml'} className="footer_link" >
                    Sitemap
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 footer-end">
                <p>IT Whizz © All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
