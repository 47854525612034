import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import{Link} from "react-router-dom";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import BlogCard from "./card";

function Seemore (){
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    window.scrollTo(0,0);
    setLoader(true);
    fetch("https://ithub.thekashth.com/api/blog").then((result) => {
      result.json().then((resp) => {
        setData(resp.data);
        setLoader(false);
      });
    });
  }, []);
  return (
    <>
    
    <div className="container my-5">
      <div className="row">
        <div className="col-12 seemore-title">
          <h2>Recent Posts</h2>
        </div>
      </div>
        <div className="row mb-5">
        { loader ? (
          <div className="job-spinner" id="spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : ''}
        {data.map((item) => (
              <div  key={item.id} className="col-md-4 blog-card" data-aos="fade-up" data-aos-duration="1500">
                <BlogCard 
                  id={item.id} 
                  title={item.title}
                  image={item.image}
                  create_at={item.created_at}
                  category_name={item.category.category_name}
                  description={item.description}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
export default Seemore;
