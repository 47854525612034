import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

function CareerForm() {
    

    let { id, name } = useParams();
    var job_id = id;

    useEffect(() => {
        document.title = `ITWhizz Apply for ${name}`;
      }, []);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [loader, setLoader] = useState(false);

    const [first_name, setFname] = useState("");
    const [last_name, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [resume, setResume] = useState([]);
    const [proposal, setProposal] = useState("");
    const [messages, setMessages] = useState("");

    const [fnameErr, setFnameErr] = useState("");
    const [lnameErr, setLnameErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [genderErr, setGenderErr] = useState("");
    const [resumeErr, setResumeErr] = useState("");
    const [messageErr, setMessageErr] = useState("");

    function saveUser(event) {
        event.preventDefault();
        console.log(resume);
        setLoader(true)
        let formData = new FormData()
        formData.append('first_name', first_name)
        formData.append('last_name', last_name)
        formData.append('email', email)
        formData.append('gender', gender)
        formData.append('resume', resume)
        formData.append('proposal', proposal)
        formData.append('job_id', job_id)

        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        let data = { first_name, last_name, email, gender, resume,  proposal , job_id };

        axios.post("http://ithub.thekashth.com/api/job/job-request", formData).then((resp) => {
            
               
                setLoader(false)
                console.log("resp", resp);
                if (resp.data.status === true) {
                    setFname("");
                    setLName("");
                    setEmail("");
                    setGender("");
                    setResume("");
                    setProposal("");
                    setMessages(true);
                    setGenderErr("");
                    setResumeErr("");
                    setMessageErr("");
                    handleShow();
                    setLoader(false)
                } else {
                    if (resp.data.error) {
                        setFnameErr(resp.data.error.first_name);
                    }
                    if (resp.error) {
                        setLnameErr(resp.data.error.last_name);
                    }
                    if (resp.error) {
                        setEmailErr(resp.data.error.email);
                    }
                    if (resp.error) {
                        setGenderErr(resp.data.error.gender);
                    }
                    if (resp.error) {
                        setResumeErr(resp.data.error.resume);
                    }
                    if (resp.error) {
                        setMessageErr(resp.data.error.message);
                    }
                    setMessages(true);
                    setLoader(false)
                }
        }).catch((err) => {
            console.log(err)
        });

    

    }

    return (
        <>
            <div id="contact" className="container">
                <div className="row">
                    <div className="col-md-12  contact-title">
                        <h2>Form Fill</h2>
                        <p>This Form for {name}</p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-10">
                        <div className="contact-card">
                            <div className="container contact-container">
                                <div className="row">
                                    <div className="col-md-10 form ">
                                        <Form onSubmit={saveUser}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <Form.Label>First Name<span>*</span></Form.Label>
                                                        <Form.Control
                                                            type="name"
                                                            required
                                                            value={first_name}
                                                            onChange={(e) => {
                                                                setFname(e.target.value);
                                                                if (e.target.value.length > 0) {
                                                                  setFnameErr("");
                                                                } else {
                                                                  setFnameErr(
                                                                    "The first name field is required."
                                                                  );
                                                                }
                                                              }}
                                                            id="exampleInputName"
                                                            placeholder="Enter Your First Name"
                                                        />

                                                        <Form.Text className="text-muted"></Form.Text>

                                                        {fnameErr ? (
                                                            <span className="danger">{fnameErr} </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Label>Last Name<span>*</span></Form.Label>
                                                        <Form.Control
                                                            type="name"
                                                            required
                                                            value={last_name}
                                                            onChange={(e) => {
                                                                setLName(e.target.value);
                                                                if (e.target.value.length > 0) {
                                                                    setLnameErr("");
                                                                } else {
                                                                    setLnameErr("The last name field is required.");
                                                                }
                                                            }}
                                                            id="exampleInputName"
                                                            placeholder="Enter Your Last Name"
                                                        />
                                                        <Form.Text className="text-muted"></Form.Text>
                                                        {lnameErr ? (
                                                            <span className="danger">{lnameErr} </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Form.Label>Email<span>*</span></Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            required
                                                            value={email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                                if (e.target.value.length > 0) {
                                                                    setEmailErr("");
                                                                } else {
                                                                    setEmailErr("The email field is required.");
                                                                }
                                                            }}
                                                            id="exampleInputEmail1"
                                                            placeholder="Enter your email"
                                                        />
                                                        {emailErr ? (
                                                            <span className="danger">{emailErr} </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Form.Label>Gender<span>*</span></Form.Label>
                                                        <Form.Select

                                                            name="gender"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                console.log(e.target.value)
                                                                setGender(e.target.value);
                                                                if (e.target.value.length > 0) {
                                                                    setGenderErr("");
                                                                } else {
                                                                    setGenderErr("The email field is required.");
                                                                }
                                                            }}
                                                            aria-label="Default select example" required>
                                                            <option value="" disabled>Gender Type</option>
                                                            <option value="Female" >Female</option>
                                                            <option value="male">Male</option>
                                                        </Form.Select>
                                                        {genderErr ? (
                                                            <span className="danger">{genderErr} </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Form.Label>Resume<span>*</span></Form.Label>
                                                        <Form.Control
                                                         onChange={(e) => {
                                                            console.log(e.target.files[0])
                                                            setResume(e.target.files[0]);
                                                            if (e.target.value.length > 0) {
                                                              setResumeErr("");
                                                            } else {
                                                              setResumeErr(
                                                                "The first name field is required."
                                                              );
                                                            }
                                                          }}
                                                            type="file"
                                                            className="form-control"
                                                            placeholder="Date Of Birth*"
                                                            required
                                                            accept="application/pdf"
                                                            id="resumt"
                                                        />
                                                        {genderErr ? (
                                                            <span className="danger">{genderErr} </span>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-md-12 ">
                                                        <div className="message">
                                                            <div className="form-group">
                                                                <Form.Label>Proposal<span>*</span></Form.Label>
                                                                <textarea
                                                                    value={proposal}
                                                                    required
                                                                    onChange={
                                                                        (e) => {
                                                                        setProposal(e.target.value);
                                                                        if (e.target.value.length > 0) {
                                                                            setMessageErr("");
                                                                        } else {
                                                                            setMessageErr(
                                                                                "The message field is required."
                                                                            );
                                                                        }
                                                                    }}
                                                                    className="form-control"
                                                                    id="exampleFormControlTextarea1"
                                                                    placeholder="Your Proposal"
                                                                    rows="4"
                                                                ></textarea>
                                                            </div>
                                                            {messageErr ? (
                                                                <span className="danger">{messageErr} </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="send-message">
                                                {loader ? (
                                                    <button className="send-btn">
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </button>
                                                ) : <button type="submit" className="send-btn  text-light">
                                                    Send Message
                                                </button>}

                                            </div>
                                            <div className="message">
                                                {messages ? (
                                                    <Modal show={show} onHide={handleClose} centered>
                                                        <div className="modal-text">
                                                            <Modal.Body >Your job request is a sended. We will conact with you.

                                                            </Modal.Body>
                                                        </div>
                                                        <div className="modal-btn">
                                                            <Button onClick={handleClose}>
                                                                Ok
                                                            </Button>
                                                        </div>
                                                    </Modal>
                                                ) : null}
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CareerForm;
