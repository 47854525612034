import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

function Contact() {
  

  useEffect(() => {
    document.title = `Contact Us`;
    window.scrollTo(0, 0);
  }, []);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loader, setLoader] = useState(false);

  const [first_name, setFname] = useState("");
  const [last_name, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState("");

  const [fnameErr, setFnameErr] = useState("");
  const [lnameErr, setLnameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [messageErr, setMessageErr] = useState("");

  function saveUser(event) {
    event.preventDefault();
    setLoader(true);
    console.log({ first_name, last_name, email, message });
    let data = { first_name, last_name, email, message };
    fetch("https://ithub.thekashth.com/api/message", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((result) => {
      result.json().then((resp) => {
        // console.log("resp", resp);
        if (resp.status === true) {
          setFname("");
          setLName("");
          setEmail("");
          setMessage("");
          setMessages("User created successfully");
          setFnameErr("");
          setLnameErr("");
          setEmailErr("");
          setMessageErr("");
          handleShow();
          setLoader(false);
        } else {
          if (resp.error) {
            setFnameErr(resp.error.first_name);
          }
          if (resp.error) {
            setLnameErr(resp.error.last_name);
          }
          if (resp.error) {
            setEmailErr(resp.error.email);
          }
          if (resp.error) {
            setMessageErr(resp.error.message);
          }
          setMessages("Some error occured");
          setLoader(false);
        }
      });
    });
  }

  return (
    <>
      <div id="contact" className="container">
        <div className="row">
          <div className="col-md-12  contact-title">
            <h2>Contact Us</h2>
            <p>Any question or remarks? Just write us a message!</p>
          </div>
        </div>
        <div className="row">
          <div className="contact-card" data-aos-duration="1100">
            <div className="container contact-container">
              <div className="row">
                <div className="col-md-5 contact-number">
                  <div className="information">
                    <h3>Contact Information</h3>
                    <p>
                      Have any questions or comments? Use this form to contact
                      us at any time.
                    </p>
                    <div className="contact-number-icon">
                      <a href="#" className="hover">
                        <i className="fa-solid fa-phone"></i>
                        +91 8502003023
                      </a>
                      <a href="#">
                        <i className="fa-solid fa-envelope"></i>
                        itwhizzpvt@gmail.com
                      </a>
                      <a href="#">
                        <i className="fa-solid fa-location-dot"></i>
                        salawas jodhpur rajasthan
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 contact-number-buttom">
                      <div className="contact-number-buttom-icon">
                        <a href="">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                        <a href="">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                        <a href="">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                        <a href="">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                        <a href="">
                          <i className="fa-brands fa-whatsapp"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 form ">
                  <Form onSubmit={saveUser}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 ">
                          <div class="form-floating mb-3">
                            <Form.Control
                              type="name"
                              required
                              value={first_name}
                              onChange={(e) => {
                                setFname(e.target.value);
                                if (e.target.value.length > 0) {
                                  setFnameErr("");
                                } else {
                                  setFnameErr(
                                    "The first name field is required."
                                  );
                                }
                              }}
                              id="floatingInput"
                              placeholder="First Name"
                            />
                            <Form.Label for="floatingInput">
                              First Name <span>*</span>
                            </Form.Label>
                            <Form.Text className="text-muted"></Form.Text>

                            {fnameErr ? (
                              <span className="danger">{fnameErr} </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-floating mb-3">
                            <Form.Control
                              type="name"
                              required
                              value={last_name}
                              onChange={(e) => {
                                setLName(e.target.value);
                                if (e.target.value.length > 0) {
                                  setLnameErr("");
                                } else {
                                  setLnameErr(
                                    "The last name field is required."
                                  );
                                }
                              }}
                              id="exampleInputName"
                              placeholder="Last Name*"
                            />
                            <Form.Label for="floatingInput">
                              Last Name <span>*</span>
                            </Form.Label>
                            <Form.Text className="text-muted"></Form.Text>
                            {lnameErr ? (
                              <span className="danger">{lnameErr} </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div class="form-floating mb-3">
                            <Form.Control
                              type="email"
                              required
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                if (e.target.value.length > 0) {
                                  setEmailErr("");
                                } else {
                                  setEmailErr("The email field is required.");
                                }
                              }}
                              id="exampleInputEmail1"
                              placeholder="email*"
                            />
                            <Form.Label for="floatingInput">
                              Email <span>*</span>
                            </Form.Label>
                            {emailErr ? (
                              <span className="danger">{emailErr} </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12 ">
                          <div className="message">
                            <div className="form-floating message-box">
                                <textarea
                                  value={message}
                                  required
                                  onChange={(e) => {
                                    setMessage(e.target.value);
                                    if (e.target.value.length > 0) {
                                      setMessageErr("");
                                    } else {
                                      setMessageErr(
                                        "The message field is required."
                                      );
                                    }
                                  }}
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  placeholder="Message*"
                                  rows="6"
                                ></textarea>
                                <Form.Label for="floatingInput">
                                  Message <span>*</span>
                                </Form.Label>
                            </div>
                            {messageErr ? (
                              <span className="danger">{messageErr} </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="send-message">
                      {loader ? (
                        <button className="send-btn">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </button>
                      ) : (
                        <button type="submit" className="send-btn">
                          Send Message
                        </button>
                      )}
                    </div>
                    <div className="message">
                      {messages ? (
                        <Modal show={show} onHide={handleClose} centered>
                          <div className="modal-text">
                            <Modal.Body>
                              Your message is a sended. We will conact with you.
                            </Modal.Body>
                          </div>
                          <div className="modal-btn">
                            <Button onClick={handleClose}>Ok</Button>
                          </div>
                        </Modal>
                      ) : null}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contact;
