import React from "react";

import Header from "./Header";
import Services from "./Services";
import Slider from "./Slider"
import Blog from "./Blog";
import { TabTitle } from "../Utils/Gunrelfuction";

export default function Home() {
    TabTitle('Home')
    window.scrollTo(0,0);
return(
    <>
        <Header/>
        <Services />
        {/* <Slider /> */}
        <Blog />
        </>
    )
}