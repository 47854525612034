import React, { useEffect, useState } from "react";
import { Component } from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { Modal, Nav, Card } from "react-bootstrap";
import moment from "moment";
import check from "../Blogcomponents/check";
// import Spinner from "react-bootstrap/Spinner";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Products() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  function searchController(val) {
    setLoader(true);
    fetch(`https://ithub.thekashth.com/api/blog/search`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ search: val }),
    }).then((result) => {
      result.json().then((resp) => {
        setData(resp.data);
        setLoader(false);
      });
    });
  }
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const blog_page = location.pathname.slice(0,5);
  return (
    <>
      {blog_page !== "/Blog" ? (
        <>
          <div className="call-icon">
            <Link to="/Contacts" className="nav-phone-button">
              <button type="button" className="nav-btn">
                <i className="fa-solid fa-phone"></i>
              </button>
            </Link>
            <Link to="/Contacts" className="nav-phone-text">
              <button className="call-btn text-dark">Contact Us</button>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="call-icon">
            <Link  type="button"
              onClick={handleShow}
              className="nav-search-icon">
              <button type="button" className="nav-btn">
              <i className="fas fa-search text-dark"></i>
              </button>
            </Link>
            <Link type="button"
              onClick={handleShow}
              className="nav-search-btn" >
              <button className="search-btn text-dark">Search</button>
            </Link>

          </div>
          <Modal style={{zIndex : '9999'}}  className="modal-container custom-map-modal" fullscreen="xxl-down" show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <div className="container mb-3">
              <div className="row search-row">
                <div className="col-md-7">
                  <div className="search">
                    <i className="fa fa-search"></i>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search anything...."
                      onChange={(e) => {
                        searchController(e.target.value)
                        setSearchInput(e.target.value)
                      }}
                      onInput={(e) =>setSearchInput(e.target.value)}
                    />
                    <Link to={`/Blog/Search/${searchInput}`} onClick={handleClose} className="search-btn-in link text-dark">Search</Link>
                  </div>
                </div>
              </div>
            </div>
            {data.length > 0 ?
              <div className="container">
              {data.map((item) => (
              
                <div className="row d-flex justify-content-center ">
                <div className="col-md-6 col-lg-6 d-block ">
                  
                  <Link
                    onClick={handleClose}
                    className="search-link"
                    to={`/Blog/Blogdetails/${item.id}/${item.title}`}
                  >
                    <h4>{item.title} {item.length}</h4>
                    <hr />
                  </Link>
                </div>
              </div>
               
              ))}
            </div> : 
            <div className="container d-flex justify-content-center">
                <h4>Not Found .</h4>
            </div>
            }
          </Modal>
        </>
      )}
    </>
  );
}





// function Fndicator(e) {
//   this.marker.style.top = e.offsetTop + "px";
//   this.marker.style.width = e.offsetWidth + "px";
// }
class New extends Component {
  state = { clicked: false, scroll: false , check : check};


  //  marker = document.querySelector('#marker')
  // item = document.querySelectorAll('ul li NavLink')
  
  handleclick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  changeBackground = () => {
    if (window.scrollY > 5) {
      this.setState({ scroll: true });
    } else {
      this.setState({ scroll: false });
    }
  };
  
  
  

  

  render() {
    
    window.addEventListener("scroll", this.changeBackground);
    
    return (
      <>
        <div
          className={
            this.state.scroll
              ? "container-fluid navbar-new active"
              : "container-fluid"
          }
        >
          <div className="container">
            <Nav className="">
              <div className="logo">
                <Link to="/">
                  <img 
                      src={require('../img/logo/logo192.png')}
                      width={100}
                      style={{marginBottom: 10}} 
                  />
                </Link>
              </div>

              <div>
                <ul
                  id="navbar"
                  className={this.state.clicked ? "#navbar active  text-dark" : "#navbar"}
                >
                  <li className="nav-item ">
                    {/* onMouseMove={(e)=> Fndicator(e.target)} */}
                    <NavLink
                      onClick={this.handleclick}
                      className={"nav-link"}
                      to="/"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      onClick={this.handleclick}
                      className={"nav-link"}
                      to="/about-us"
                    >
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/Careers"
                      className="nav-link"
                      onClick={this.handleclick}
                    >
                      Career
                    </NavLink>
                  </li>
                    <li className="nav-item">
                    <NavLink
                      onClick={this.handleclick}
                      className={"nav-link blog-navbar"}
                      to="/Blog"
                    >
                      Blog
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="call-nav-btn">
                <Products />
                <div id="mobile" onClick={this.handleclick}>
                  <i
                    id="bar"
                    className={
                      this.state.clicked
                        ? "fa-solid fa-xmark"
                        : "fa-solid fa-bars"
                    }
                  ></i>
                </div>
              </div>
            </Nav>
          </div>
        </div>
      </>
    );
  }
}
export default New;
