import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Check from "./check";

function Blogdetail() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const params = useParams();

  const { id, name } = params;
  useEffect(() => {
    document.title = `${name}`;
    window.scrollTo(0, 0);
    setLoader(true);
    fetch(`https://ithub.thekashth.com/api/blog/${id}`).then((result) => {
      result.json().then((resp) => {
        setData(resp.data);
        setLoader(false);
      });
    });
  }, []);

  return (
    <>
      <Check />
      {loader ? (
        <div className=" blog-detail-spinner" id="spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="container">
          <Helmet>
            <title>{name}</title>
            <meta name="title" content={data.meta_key} />
            <meta name="description" content={data.meta_desc} />
          </Helmet>
          <div className="row">
            <div className="col-12 blog-detail ">
              <div className="col-md-8 blog-detail-img">
                <img
                  src={"https://ithub.thekashth.com/" + data.image}
                  className="blog-main-img img-fluid"
                  alt=""
                />
              </div>

              <div className="col-md-8 blog-main-img-text">
                <p>Post by ITWhizz</p>
                <p>{moment(data.created_at).format("DD MMM YYYY")}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="blog-title">
                <h2>{data.title}</h2>
              </div>
              <div className="blog-deteil">
                <div
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Blogdetail;
