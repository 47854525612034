import React, { useEffect, useState } from "react";
import a from "../img/card/1.png";
import b from "../img/card/2.png";
import BlogCard from '../Blogcomponents/card';
import Card from "react-bootstrap/Card";
import{Link} from "react-router-dom";
import moment from "moment";
import {useParams} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Category_Name (){
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const {name} = params;
  useEffect(() => {
    window.scrollTo(0,0);
    setLoader(true);
    fetch(`https://ithub.thekashth.com/api/category/${name}`).then((result) => {
      result.json().then((resp) => {
        setData(resp.data);
        setLoader(false);
      });
    });
  }, []);
  console.log(name);
  return (
    <>
    <div className="container my-5">
      <div className="row">
        <div className="col-12 seemore-title">
          <h2>{name}</h2>
        </div>
      </div>
        <div className="row mb-5">
       { loader ? (
          <div className="job-spinner" id="spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : ''}
        {data.map((item) => (
              <div className="col-md-4 blog-card" data-aos="fade-up" data-aos-duration="1500">
                <Card style={{ width: "28rem" }}>
                  <Link className=""  to={`/Blog/Blogdetails/${item.id}/${item.title}`}>
                    <LazyLoadImage effect="blur"
                                   src={"https://ithub.thekashth.com/" + item.image} 
                                   className="card-img-top" 
                                   variant="top"
                                   alt={item.title}
                    />
                    <Card.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-9 card-title-text">
                            <h4>{item.title}</h4>
                            <div className="card-title-name">
                              <p>
                                {moment
                                  .utc(item.created_at)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3 card-title-text-a">
                            <div>
                              <a className="card-Categray" href="#">
                                Categray
                              </a>
                              <p>{item.category.category_name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="card-text"><div dangerouslySetInnerHTML={{__html: item.description.length > 200 ? `${item.description.substring(0, 150)}........See More`: item.description}}></div></div>
                      </div>
                    </Card.Body>
                  </Link>
                </Card>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
export default Category_Name;
