import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function BlogCard(props){
    const {id , title , image ,create_at , category_name , description } = props;
    return (
        <>
            <Card style={{ width: "28rem" }}>
                  <Link className="" to={`/Blog/Blogdetails/${id}/${title}`}>
                    <LazyLoadImage effect="blur"
                                   src={"https://ithub.thekashth.com/" + image} 
                                   className="card-img-top" 
                                   variant="top"
                    />
                    <Card.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-9 card-title-text">
                            <h4>{title}</h4>
                            <div className="card-title-name">
                            <p>
                                {moment
                                  .utc(create_at)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3 card-title-text-a">
                            <div>
                              <a className="card-Categray" href="#">
                                Categray
                              </a>
                              <p>{category_name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="card-text"><div dangerouslySetInnerHTML={{__html: description.length > 200 ? `${description.substring(0, 150)}........See More`: description}}></div></div>
                      </div>
                    </Card.Body>
                  </Link>
                </Card>
        </>
    )
}