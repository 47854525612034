import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { TabTitle } from "../Utils/Gunrelfuction";


function Career() {
  TabTitle('Career')
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    window.scrollTo(0,0);
    setLoader(true);
    fetch("https://ithub.thekashth.com/api/job").then((result) => {
      result.json().then((resp) => {
        setData(resp.data);
        setLoader(false);
      });
    });
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 career-title">
            <h2>Find Jobs</h2>
          </div>
        </div>
      </div>
      {loader ? (
          <div className="job-spinner" id="spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
      <div className="container">
      {data.length  !== 0 ? data.map((item) =>(
        <div className="row job-row" data-aos="fade-up" data-aos-duration="1500">
          <div className="col-md-10  job-list">
          
            <div className="container salawas">
            
              <div className="row job">
              
                <div className="col-md-3 job-name">
                  <h3>{item.title}</h3>
                  <p>{item.job_category}</p>
                </div>
                 
                <div className="col-md-8 job-detail">
                  <p>
                    <i className="fa-solid fa-location-dot"></i>
                    {item.on_site_remote}
                  </p>
                  <p>
                    <i className="fa fa-graduation-cap"></i>
                    {item.exprience}
                  </p>
                  <p>
                    <i className="fa-regular fa-clock"></i>
                    {item.job_type}
                  </p>
                </div>
              </div>
             
              <div className="row job-discretion">
                <div className="col-md-11 ">
                  <h3>Job Description</h3>
                  <p>
                  {item.description}
                  </p>
                </div>
              </div>
              <div className="row job-posted">
                <div className="col-md-6 col-8 ">
                  <p>
                    <i className="fa-regular fa-clock"></i>  {moment
                                  .utc(item.created_at)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}
                    
                  </p>
                </div>
                <div className="col-md-6 col-4 job-btn">
                  <div className="view-btn ">
                    <Link to={`/Careers/${item.title}/${item.id}`}><button className="job-view-btn  text-dark">Apply Now</button></Link>
                  </div>
                </div>
              </div>

            </div>
             
          </div>
        </div>
        )) : 
        <div className="container d-flex justify-content-center pt-5 pb-5">
            <h3>Now No have Any Opening.</h3>
        </div>
        } 
        
      </div>
      )}
    </>
  );
}
export default Career;
