import React from "react";

import "./Styles/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/mukta";

import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Services from "./components/Services";
import Footer from "./components/Footer"; 
import Slider from "./components/Slider";
import About from "./components/About";
import Blog from "./components/Blog";
import Blogdetail from "./Blogcomponents/Blogdetail";
import BlogPosts from "./Blogcomponents/Posts";
import Contact from "./components/Contact";
import Seemore from "./Blogcomponents/Seemore";
import Career from "./components/career";
import Careerform from "./components/Careerform";
import Search from "antd/es/transfer/search";
import Category_Name from "./components/CategoryName";


import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import SearchPost from "./components/Search";

function App() {
  return (
    <Router>
       <Navbar /> 
        <Routes>
         
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/Work" element={<Slider />}></Route>
          <Route exact path="/Services" element={<Services />}></Route>
          <Route exact path="/about-us" element={<About />}></Route>
          <Route exact path="/Blog" element={<BlogPosts />}></Route>
          <Route exact path="/Blog/Blogdetails/:id/:name" element={<Blogdetail />}></Route>
          <Route exact path="/Contacts" element={<Contact />}></Route>
          <Route exact path="/Blog/Seemores" element={<Seemore />}></Route>
          <Route exact path="/Blog/Category/:name" element={<Category_Name />}></Route>
          <Route exact path="/Careers" element={<Career />}></Route>
          <Route exact path="/Careers/:name/:id" element={<Careerform />}></Route>
          <Route exact path="/Blog/Search" element={<Search/>}></Route>
          <Route exact path="/Blog/Search/:name" element={<SearchPost/>}></Route>
          
        </Routes>
        <Footer/> 
    </Router>
  );
}
export default App;
