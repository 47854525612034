import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Card from "react-bootstrap/Card";
import { Result } from "antd";
import Spinner from "react-bootstrap/Spinner";


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


function Blog() {
 
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    window.scrollTo(0,0);
    setLoader(true);
    fetch("https://ithub.thekashth.com/api/blog").then((result) => {
      result.json().then((resp) => {
        setData(resp.data);
        setLoader(false);
      });
    });
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 blog-text my-5">
            <h2>Blog Articals</h2>
            <div className="btn1 hover">
              <Link to="/Blog/Seemores">
                <button className="blog-btn">See More</button>
              </Link>
            </div>
          </div>
        </div>
        {loader ? (
          <div className="featued-spinner" id="spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
        <div className="row mb-5 blog-article">
          {data.slice(0, 2).map((item, index) => (
            <div className="col-md-6 blog-card" key={index}>

              <Card 
                data-aos={index === 0 ? "zoom-in-right" : "zoom-in-left"}   
                data-aos-duration="1500"               
                style={{ width: "28rem" }} >
                <Link className=""  to={`/Blog/Blogdetails/${item.id}/${item.title}`}>
                <LazyLoadImage effect="blur"
                  src={"https://ithub.thekashth.com/" + item.image}
                  className="card-img-top"
                  variant="top"
                  alt={item.title}
                />
                <Card.Body>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-9 card-title-text">
                        <h4>{item.title}</h4>
                        <div className="card-title-name">
                          <p>
                            {moment
                              .utc(item.created_at)
                              .local()
                              .startOf("seconds")
                              .fromNow()}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3 card-title-text-a">
                        <div>
                          <a className="" href="#">
                            Categray
                          </a>
                          <p>{item.category.category_name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="card-text"><div dangerouslySetInnerHTML={{__html: item.description.length > 200 ? `${item.description.substring(0, 150)}........See More`: item.description}}></div></div>
                  </div>
                </Card.Body>
                </Link>
              </Card>
            </div>
          ))}


          <div className="blg-btm">
            <div className="btn1 hover">
              <Link to="Blog/Seemores">
                <button className="btm-blog-btn">See More</button>
              </Link>
            </div>
          </div>
        </div>
        )}
      </div>
    </>
  );
}
export default Blog;
