import React, { useEffect, useState } from "react";
import a from "../img/card/1.png";
import b from "../img/card/2.png";
import BlogCard from '../Blogcomponents/card';
import Card from "react-bootstrap/Card";
import{Link , useParams} from "react-router-dom";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function SearchPost(){
  const params = useParams();
  const {id , name} = params;
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  console.log(name)
  
  useEffect(() => {
    setLoader(true);
    fetch(`https://ithub.thekashth.com/api/blog/search`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ search: name }),
    }).then((result) => {
      result.json().then((resp) => {
        setData(resp.data);
        setLoader(false);
      });
    });

  },[name])
  console.log(data)
 
  return (
    <>
      <div className="container my-5">
          <div className="row">
            <div className="col-12 seemore-title">
              <h2 style={{opacity : '0.8'}} >Search - {name}</h2>
            </div>
          </div>
          {loader ? (
              <div className="featued-spinner">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="row blog-row mb-5">
                {data.map((item) => (
                  <div className="col-md-4 blog-card">
                    <Card style={{ width: "28rem" }}>
                      <Link className="" to={`/Blog/Blogdetails/${item.id}/${item.title}`}>
                        {/* <Card.Img
                          loading="lazy"
                          variant="top"
                          src={"https://ithub.thekashth.com/" + item.image}
                        /> */}
                        <LazyLoadImage effect="blur"
                                      src={"https://ithub.thekashth.com/" + item.image} 
                                      className="card-img-top" 
                                      variant="top"
                                      alt={item.id}
                        />
                        <Card.Body>
                          <div className="container">
                            <div className="row">
                              <div className="col-md-9 card-title-text">
                                <h4>{item.title}</h4>
                                <div className="card-title-name">
                                  <p>
                                    {moment
                                      .utc(item.created_at)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3 card-title-text-a">
                                <div>
                                  <a className="card-Categray" href="#">
                                    Categray
                                  </a>
                                  <p>{item.category.category_name}</p>
                                </div>
                              </div>
                            </div>
                            <div className="card-text"><div dangerouslySetInnerHTML={{__html: item.description.length > 200 ? `${item.description.substring(0, 150)}........See More`: item.description}}></div></div>
                          </div>
                        </Card.Body>
                      </Link>
                    </Card>
                  </div>
                ))}
              </div>
            )}
      </div>
    </>
  )
}
export default SearchPost;
