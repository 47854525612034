import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { TabTitle } from "../Utils/Gunrelfuction";  

function Posts() {
  TabTitle('Blog')
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    window.scrollTo(0,0);
    setLoader(true);
    fetch("https://ithub.thekashth.com/api/blog").then((result) => {
      result.json().then((resp) => {
        setData(resp.data);
        setLoader(false);
      });
    });
    fetch("https://ithub.thekashth.com/api/category").then((result) => {
      result.json().then((resp) => {
        setCategory(resp.data);
        console.log(category);
        setLoader(false);
      });
    });
  }, []);
  
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 featued my-5">
            <h2>Featured Posts</h2>
            <div className="btn1 hover">
              <Link to="/Blog/Seemores" className="see-btn">
                See More
                <i className="fa-solid fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
        {loader ? (
          <div className="featued-spinner" id="spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="row blog-row mb-5">
            {data.map((item) => (
              <div key={item.id} className="col-md-4 blog-card" data-aos="fade-up" data-aos-duration="1500">
                <Card style={{ width: "28rem" }}>
                  <Link className="" to={`/Blog/Blogdetails/${item.id}/${item.title}`}>
                    {/* <Card.Img
                      loading="lazy"
                      variant="top"
                      src={"https://ithub.thekashth.com/" + item.image}
                    /> */}
                    <LazyLoadImage effect="blur"
                                   src={"https://ithub.thekashth.com/" + item.image} 
                                   className="card-img-top" 
                                   variant="top"
                                   alt={item.id}
                    />
                    <Card.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-9 card-title-text">
                            <h4>{item.title}</h4>
                            <div className="card-title-name">
                              <p>
                                {moment
                                  .utc(item.created_at)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3 card-title-text-a">
                            <div>
                              <a className="card-Categray" href="#">
                                Categray
                              </a>
                              <p>{item.category.category_name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="card-text"><div dangerouslySetInnerHTML={{__html: item.description.length > 200 ? `${item.description.substring(0, 150)}........See More`: item.description}}></div></div>
                      </div>
                    </Card.Body>
                  </Link>
                </Card>
              </div>
            ))}

            <div className="buttom-btn1 hover">
              <a href="#" className="buttom-see-btn">
                See More
                <i className="fa-solid fa-arrow-right"></i>
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="container">
        <div className="row topics-row">
          <div className="col-md-12 recent my-5">
            <h2>All topics</h2>
          </div>
        </div>
        <div className="row">
          <div className="col all-topics">
            {
              category.map((cate)=> (
                <div key={cate.id}>
                   <Link  to={`/Blog/Category/${cate.category_name}`} style={{ textDecoration: 'none'}}>
                      <div className="all-topics-text" >
                          <LazyLoadImage effect="blur"
                                        src={"https://ithub.thekashth.com/" + cate.category_img} 
                                        width={30}
                                        height={30}
                                        variant="top"
                                        alt={cate.id}
                          />
                          <a style={{ textDecoration: 'none'}} className="hover">
                            {cate.category_name}
                          </a>
                      </div>
                  </Link>
                </div>
              ))
            }
            
          </div>
        </div>
      </div>
    </>
  );
}
export default Posts;
