import React from "react";
import AOS from 'aos';
import { useEffect } from "react";
import 'aos/dist/aos.css';
import a from "../img/services/graphic.png";
import b from "../img/services/b.png";
import d from "../img/services/mobile.png";
import seo from "../img/services/seo.png"
function Services() {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <section id="rafiq" className="services mt-5">
        <div className="container">
          <div className="services-title">
            <h2 className="py-3">Our Services</h2>
          </div>

          <div className="row">
            <div className="col-md-4 services-col" data-aos="fade-up" data-aos-duration="1500">
              <div className="services-img">
                <img variant="top" src={a} className="image-resize" />
              </div>
              <h3>Web Development</h3>
              <p  align="center">
              Build a stunning and user-friendly website that stands out from the crowd with our professional web development services.
              </p>
            </div>
            <div className="col-md-4 services-col" data-aos="fade-up" data-aos-duration="1500">
              <div className="services-img">
                <img variant="top" src={d} className="image-resize" />
              </div>
              <h3>App Development</h3>
              <p  align="center">
                Turn your app idea into reality with our expert app development services, delivering seamless experiences across platforms.
              </p>
            </div>
            <div className="col-md-4 services-col" data-aos="fade-up" data-aos-duration="1500">
              <div className="services-img">
                <img variant="top" src={seo} className="image-resize" />
              </div>
              <h3>Seo</h3>
              <p align="center">
              Boost your online visibility and drive organic traffic to your website with our results-driven SEO services.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Services;
