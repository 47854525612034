import React, {useEffect, useState} from "react";
import Spinner from "react-bootstrap/Spinner";
import SideImage from "../img/about/svg.svg"
import { TabTitle } from "../Utils/Gunrelfuction";

export default function About() {
    TabTitle('About')
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        window.scrollTo(0,0);
    })
    setInterval(() => {
        setLoader(false)
    }, 300)
    return (
        <>
            <div className="container">
                <div className="row mb-2">
                    <div className="col-md-12 career-title">
                        <h2>About Us</h2>
                    </div>
                </div>
                
                {loader ? (
          <div className="job-spinner" id="spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
                <div className="container mb-5">
                    <div className="row" id="about">
                        <div className="col-lg-7 order-2 order-lg-1 mt-3">
                            <h3>We are A digital Production Team</h3>
                            <p className="mt-3"><span>itWhizz </span>  is a software services company that specializes in providing custom software development, web development, mobile app development, and cloud-based solutions. Our mission is to help businesses of all sizes streamline their operations and achieve their goals through innovative technology solutions.</p>
                            <p className="mt-1">
                                We are passionate about delivering high-quality software services that are tailored to each client's unique needs. Our team of experts has years of experience in the industry and stays up-to-date with the latest technologies and trends to ensure that our solutions are cutting-edge and effective.
                            </p>
                            <p className="mt-1">
                                At itWhizz, we value integrity, teamwork, and customer satisfaction above all else. We believe that open communication and collaboration are essential to building strong relationships with our clients and delivering successful outcomes.
                            </p>
                            <p className="mt-1">
                                If you are looking for a reliable software services provider that can help take your business to the next level, look no further than itWhizz. Contact us today to learn more about our services and how we can help you succeed.
                            </p>
                        </div>
                        <div className="col-lg-5 order-1">
                            <img src={SideImage} alt={SideImage} className="img-fluid"/>
                        </div>
                    </div>
                </div>
      )}
            </div>
        </>
    )
}